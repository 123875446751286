@import "src/styles/colors";
@import "src/styles/mixins";

.content{
  height: 836px;
  position: relative;

  & .building{
    position: absolute;
    left: 0;
    bottom: 0;
  }

  & .about_us{
    @include display-flex(space-between, flex-start);
    padding: 80px 48px 0 32px;
    position: relative;
    z-index: 1;

    & .text{
      width: 657px;
      @include display-flex;
      flex-direction: column;
      gap: 32px;

      & h2{
        @include fonts(700, 48px, 67px, $text-dark);
      }

      & p{
        @include fonts(400, 16px, 22px, $text-body);
      }
    }

    & .image_block{
      width: 557px;
      height: 338px;
      flex-shrink: 0;
      //border-radius: 16px;
      //border: 1px solid $bg-primary;
      position: relative;
      z-index: 1;

      & .border{
        border: 1px solid $bg-primary;
        border-radius: 16px;
        height: 100%;
        z-index: 1;
        position: relative;
      }

      & .play{
        position: absolute;
        left: 266px;
        top: 156px;
        cursor: pointer;
        z-index: 2;
        transition: all .5s ease-in-out;

        &:hover{
          transform: scale(1.1);
          transition: all .5s ease-in-out;
        }
      }

      & .pic{
        position: absolute;
        left: 14px;
        bottom: -16px;
        border-radius: 8px;
        //background: url(<path-to-image>), lightgray 50% / cover no-repeat;
      }

    }
  }

  & .cards{
    //margin-top: 80px;
    @include display-flex(space-between);
    gap: 24px;
    padding: 80px 31px 0;
  }
  @media screen and (max-width: 1000px) and (min-width: 501px){
    height: auto;

    & .building{
      opacity: .5;
    }

    & .about_us{
      padding: 40px 28px 0 22px;

      & .text{
        width: auto;
        gap: 22px;

        & h2{
          @include fonts(700, 38px, 47px, $text-dark);
        }

        & p{
          @include fonts(400, 16px, 22px, $text-dark);
        }
      }

      & .image_block{
        width: 356px;
        height: 238px;

        & .play{
          left: 164px;
          top: 107px;
        }

        & .pic{
          width: 100%;
          height: 100%;
        }
      }
    }

    & .cards{
      gap: 12px;
      padding: 40px 16px 0;
    }
  }

  @media screen and (max-width: 501px){
    height: auto;

    & .building{
      position: absolute;
      left: 0;
      bottom: auto;
      top: 20px;
      opacity: .5;
    }

    & .about_us{
      @include display-flex(space-between, flex-start);
      flex-direction: column;
      padding: 40px 16px 0;

      & .text{
        width: 100%;
        gap: 24px;

        & h2{
          @include fonts(700, 42px, 52px, $text-dark);
          text-align: center;
        }
      }

      & .image_block{
        margin-top: 24px;
        padding-right: 14px;
        width: 100%;
        height: 200px;


        & .play{
          left: calc(50% - 14px);
          top: 86px;
        }

        & .pic{
          width: calc(100% - 14px);
          height: 100%;
        }
      }
    }

    & .cards{
      @include display-flex(flex-start);
      flex-direction: column;
      gap: 16px;
      padding: 60px 16px 0;
    }
  }
}

.card{
  width: 33%;
  height: 242px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid $bg-primary;
  @include display-flex(flex-start, flex-start);
  flex-direction: column;
  gap: 16px;
  background-color: $bg-neutral;
  position: relative;
  z-index: 1;

  & .title_block{
    @include display-flex(flex-start);
    gap: 16px;

    & .img{
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      //background-color: $bg-primary;
      border-radius: 16px;
      background: var(--gradient-primary, linear-gradient(135deg, #E7B000 0%, #F1C32D 100%));
      @include display-flex
    }

    & h3{
      @include fonts(700, 24px, 24px, text-dark);
      letter-spacing: 0.25px;
    }
  }

  & p{
    @include fonts(400, 14px, 20px, $text-body);
  }

  @media screen and (max-width: 1000px) and (min-width: 501px){
    height: 300px;
    padding: 12px;
    border-radius: 8px;
    overflow-y: auto;

    & .title_block{
      @include display-flex(flex-start);
      flex-direction: column;
      gap: 16px;

      & .img{
        width: 46px;
        height: 46px;
      }

      & h3{
        @include fonts(700, 20px, 20px, text-dark);
      }
    }

    & p{
      @include fonts(400, 14px, 20px, $text-body);
    }
  }

  @media screen and (max-width: 501px){
    width: 100%;
    height: auto;
    padding: 16px;
  }
}