@import "src/styles/colors";
@import "src/styles/mixins";

.feedback{

  & .experience{
    padding: 80px 0;
    @include display-flex;
    flex-direction: column;
    gap: 32px;
    background: $bg-primary;

    & h2{
      @include fonts(700, 48px, 67px, $text-dark);
      letter-spacing: 0.25px;
    }

    & p{
      width: 887px;
      text-align: center;
      @include fonts(400, 24px, 34px, $text-body);
      letter-spacing: 0.25px;
    }

    & button{
      border: none;
      @include fonts(700, 20px, 28px, $text-white);
      letter-spacing: 0.25px;
      padding: 16px 24px;
      border-radius: 8px;
      background: var(--gradient-secondary, linear-gradient(138deg, #000 0%, #1A1A1A 100%));
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      transition: all .5s ease-in-out;

      &:hover{
        background: var(--gradient-primary, linear-gradient(135deg, #E7B000 0%, #F1C32D 100%));
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
        transition: all .5s ease-in-out;
      }
    }

    & .click_button{
      background: $text-dark!important;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.32)!important;
      transition: all .5s ease-in-out;
    }
  }

  & .feedback_title{
    padding: 0 32px;
    margin-top: 80px;
    @include fonts(700, 48px, 67px, $text-dark);
    letter-spacing: 0.25px;
  }

  & .feedback_block{

    & .slider{
      height: auto;
      @include display-flex;
      gap: 24px;
      padding: 0 32px;

      & .feedbackCard{
        margin-top: 32px;
        height: 215px;
        overflow-y: auto;
        width: 50%;
        padding: 24px;
        @include display-flex(center, flex-start);
        gap: 16px;
        border-radius: 8px;
        border: 1px solid $bg-primary;
        background: $bg-neutral;

        & .photo{
          width: 80px;
          height: 80px;
        }

        & .text{
          width: calc(100% - 80px);

          & h3{
            @include fonts(700, 24px, 24px, $text-dark);
            letter-spacing: 0.25px;
          }

          & h5{
            @include fonts(700, 16px, 22px, $text-dark);
            letter-spacing: 0.25px;
          }

          & p{
            margin-top: 16px;
            @include fonts(400, 14px, 20px, $text-body);
            letter-spacing: 0.25px;
          }
        }
      }
    }

    & .slider_control{
      margin: 24px 0 80px;
      @include display-flex;
      gap: 16px;

      & .line{
        width: 44px;
        height: 4px;
        background: $bg-secondary;
        cursor: pointer;
        transition: all .5s ease-in-out;
      }

      & .active_line{
        width: 124px;
        background: var(--gradient-primary, linear-gradient(135deg, #E7B000 0%, #F1C32D 100%));
        transition: all .5s ease-in-out;
      }
    }
  }

  @media screen and (max-width: 1000px) and (min-width: 501px){

    & .experience{
      padding: 40px 16px;
      gap: 26px;

      & h2{
        @include fonts(700, 36px, 47px, $text-dark);
        text-align: center;
      }

      & p{
        width: auto;
        text-align: center;
        @include fonts(400, 24px, 34px, $text-body);
      }

      & button{
        @include fonts(500, 20px, 28px, $text-white);
      }
    }

    & .feedback_title{
      padding: 0 16px;
      text-align: center;
      margin-top: 40px;
      @include fonts(700, 36px, 47px, $text-dark);
    }

    & .feedback_block{

      & .slider{
        gap: 16px;
        padding: 0 16px;

        & .feedbackCard{
          height: 265px;
          padding: 16px;
          gap: 16px;

        }
      }
    }
  }

  @media screen and (max-width: 501px){
    & .experience{
      padding: 40px 16px;
      gap: 16px;

      & h2{
        @include fonts(700, 36px, 46px, $text-dark);
        text-align: center;
      }

      & p{
        width: 100%;
        text-align: center;
        @include fonts(400, 20px, 28px, $text-body);
        letter-spacing: 0.25px;
      }

      & button{
        border: none;
        @include fonts(700, 16px, 24px, $text-white);
        padding: 8px 24px;
        width: 100%;
      }
    }

    & .feedback_title{
      padding: 0 16px;
      margin-top: 40px;
      @include fonts(700, 36px, 46px, $text-dark);
      text-align: center;
    }

    & .feedback_block{

      & .slider{
        flex-direction: column;
        gap: 16px;
        padding: 16px 16px 0;

        & .feedbackCard{
          margin-top: 0;
          height: auto;
          width: 100%;
        }
      }

      & .slider_control{
        margin: 24px 0 40px;
        gap: 8px;

        & .line{
          width: 24px;
        }

        & .active_line{
          width: 84px;
        }
      }
    }
  }
}