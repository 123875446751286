$accent: #333333;
$background-color: #e5e5e5;
$carbon: #333333;
$midday_sun: #ffdc8e;
$jazzy_jade: #51c9c2;
$ambrosia_cake: #eee9cf;
$diamond_dust: #f8f5e6;
$fresh_guacamole: #fff4ea;

$form_red: #e53d3e;
$black_coral: #5e5f71;
$pale_white: #dfdfdf;

$main_text: #1d2130;

$text-dark: #1F1800;
$text-body: #4D4D4D;
$bg-neutral: #FFF;
$text-white: #FFF;
$bg-secondary: #F4F4F4;
$bg-primary: #F7BD01;

$green: #41C602;
$error: #FFA4A4;