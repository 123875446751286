@import "src/styles/colors";
@import "src/styles/mixins";

.content{
  width: 100%;
  height: 72px;
  padding: 24px 32px 16px;
  box-shadow: 0 4px 32px 0 #00000029;
  @include display-flex(space-between);
  background-color: $bg-neutral;
  position: relative;
  z-index: 100;

  & .text{
    @include display-flex(space-between);
    gap: 32px;

    & a{
      all: unset;
      @include fonts(500, 20px, 28px, $text-dark);
      letter-spacing: 0.25px;
      cursor: pointer;
    }
  }

  & .mobile_menu {
    position: fixed;
    width: 100%;
    height: calc(100vh - 49px);
    left: 0;
    top: 49px;
    z-index: 101;
    background-color: $bg-neutral;
    border-top: .3px solid $text-body;
    padding: 0 20px;

    display: block;
    transition: display 1s ease-out;
    box-sizing: border-box;


    & .container{
      transition: all .3s ease-out;
      opacity: 1;
    }


    & .item_menu {
      height: 40px;
      border-bottom: .3px solid $text-body;
      @include display-flex;
      flex-direction: column;
      text-transform: uppercase;
      @include fonts(300, 16px, 19px, $text-dark);
      gap: 12px;
      text-decoration: none;

      & a {
        text-decoration: none;
        @include fonts(300, 16px, 19px, $text-dark);
      }
    }
  }

  & .contacts{
    margin-top: 30px;
    width: 100%;
    @include display-flex(flex-start, flex-start);
    //flex-direction: column;
    gap: 32px;

    & h3{
      @include fonts(700, 24px, 24px, $text-dark);
      letter-spacing: 0.25px;
      margin-bottom: 16px;
    }

    & .contacts_block{
      @include display-flex(flex-start, flex-start);
      flex-direction: column;
      gap: 12px;

      & .item{
        @include display-flex(flex-start);
        gap: 8px;

        & div{
          @include fonts(400, 16px, 22px, $text-body);
        }

      }
    }

    & .schedule{
      width: 180px;
      margin-left: 38px;
      @include fonts(400, 16px, 22px, $text-body);
      letter-spacing: 0.25px;
    }
  }

  @media screen and (max-width: 1000px) and (min-width: 501px){
    height: 49px;
    padding: 5px 32px;;
    position: fixed;
    top: 0;
  }

  @media screen and (max-width: 501px){
    height: 49px;
    padding: 5px 32px;;
    position: fixed;
    top: 0;

    & .contacts{
      flex-direction: column;
    }
  }
}