@import "src/styles/colors";
@import "src/styles/mixins";

.eco{
  height: 794px;
  padding: 80px 36px 80px 32px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  & .eco_title{
    @include display-flex(flex-start);
    gap: 32px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      opacity: .6;
    }

    & .line{
      width: 236px;
      border: 1px solid $bg-primary;
      flex-shrink: 0;
    }

    & .active_title{
      @include fonts(700, 20px, 28px, $text-dark);
      letter-spacing: 0.25px;
    }

    & span{
      @include fonts(500, 20px, 28px, $text-body);
      letter-spacing: 0.25px;
      white-space: nowrap;
      cursor: pointer;
      -moz-user-select: none;
      user-select: none;
    }
  }

  & .eco_case{
    margin-top: 56px;
    height: 550px;
    @include display-flex;
    gap: 22px;

    & .text{
      width: 50%;
      @include display-flex(center, flex-start);
      flex-direction: column;
      gap: 24px;

      & .status{
        @include fonts(400, 14px, 20px, $text-dark);
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid $green;
        background: $bg-neutral;
      }

      & h3{
        @include fonts(700, 24px, 34px, $text-dark);
        letter-spacing: 0.25px;
      }

      & p{
        @include fonts(400, 16px, 22px, $text-body);
        letter-spacing: 0.25px;
      }

      & .know_more{
        @include fonts(700, 20px, 28px, $text-dark);
        letter-spacing: 0.25px;
        padding: 16px 24px;
        border-radius: 8px;
        background: var(--gradient-primary, linear-gradient(135deg, #E7B000 0%, #F1C32D 100%));
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
        border: none;
        cursor: pointer;

        &:hover{
          @include fonts(700, 20px, 28px, $text-white);
          background: var(--gradient-secondary, linear-gradient(138deg, #000 0%, #1A1A1A 100%));
          box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.32);
        }
      }

      & .know_more_click{
        background: $text-dark!important;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.32)!important;
      }
    }

    & .pic{
      width: 50%;
      height: 100%;

      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @media screen and (max-width: 1000px) and (min-width: 501px){
    height: auto;
    padding: 40px 16px 40px 12px;

    & .eco_title{
      @include display-flex(flex-start);
      gap: 24px;

      & .line{
        width: 136px;
      }
    }

    & .eco_case{
      margin-top: 0;
      height: auto;
      gap: 16px;

      & .text{
        gap: 16px;
      }
    }
  }

  @media screen and (max-width: 501px){
    height: auto;
    padding: 60px 16px 60px;

    & .eco_title{
      @include display-flex(flex-start);
      gap: 16px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        opacity: .6;
      }

      & .line{
        width: 50px;
      }

      & .active_title{
        @include fonts(700, 16px, 22px, $text-dark);
      }

      & span{
        @include fonts(500, 16px, 22px, $text-body);
        letter-spacing: 0.25px;
      }
    }

    & .eco_case{
      margin-top: 16px;
      height: auto;
      @include display-flex;
      flex-direction: column;
      gap: 16px;

      & .text{
        width: 100%;
        @include display-flex(center, flex-start);
        flex-direction: column;
        gap: 16px;

        & .status{
          @include fonts(400, 14px, 20px, $text-dark);
          padding: 6px 16px;
        }

        & h3{
          @include fonts(700, 24px, 28px, $text-dark);
        }

        & p{
          @include fonts(400, 16px, 20px, $text-body);
          letter-spacing: 0.25px;
        }

        & .know_more{
          width: 100%;
          padding: 8px 24px;
        }
      }

      & .pic{
        width: 100%;
        height: 100%;

        & img{
          width: 100%;
        }
      }
    }
  }
}